<template>
    <div class="card-producto d-middle m-2 p-1" :class="{'border-general':producto.promocion && producto.data_promocion.borde}">
        <div class="position-relative">
            <img class="br-10 cr-pointer obj-cover" height="90" width="90" :src="producto.imagen" alt="" @click.stop="detalle(producto)" />
            <p v-show="$tienda.tipo_impuesto === 1 && producto.porcentaje_impuesto > 0" class="bg-general2 br-10 text-white f-10 text-center">+ IVA</p>
            <p v-show="$tienda.tipo_impuesto === 2 && producto.porcentaje_impuesto > 0" class="bg-general2 br-10 text-white f-10 text-center">IVA incluido</p>
        </div>
        <div class="col px-2">
            <p class="nombre lh-18 f-500 text-general">{{ producto.nombre }}</p>
            <p class="text-general2">{{ producto.presentacion }}</p>
            <div v-show="producto.en_pedido" class="row my-1">
                <div class="col-12 f-600">
                    Stock Disponible
                </div>
                <div v-for="(s,key) in producto.stockCedis" :key="key" class="col-12">
                    {{ s.cedi }}: {{ s.stock }} und
                </div>
                <div v-if="producto.stockCedis.length === 0" class="col-12">
                    Se vende sin Stock
                </div>
            </div>
            <div v-show="producto.en_pedido" class="row mx-0 align-items-center pb-1">
                <span class="text-general f-600">{{ convertMoneyTendero(funValorEnpedido(producto.total_final),$tienda.idm_moneda) }}</span>
                <strike v-show="producto.promocion" class="ml-2 f-12">
                    {{ convertMoneyTendero(funValorEnpedido(producto.total_teorico), $tienda.idm_moneda) }}
                </strike>
                <i class="icon-trash-empty ml-auto f-16 text-danger border br-5 border-danger mr-1 cr-pointer" @click="cambiar_cantidad(0, producto)" />
                <el-input-number
                v-model="producto.cantidad"
                :min="parseFloat(producto.compra_minima)"
                :max="maximaCompra"
                :step="parseFloat(producto.cantidad_minima)"
                :step-strictly="entero"
                :precision="precision"
                size="small"
                style="width:130px;"
                @change="cambiar_cantidad($event, producto)"
                />
            </div>
            <div v-if="!producto.en_pedido" class="row mx-0 align-items-center pb-1">
                <span v-if="producto.promocion" class="text-general f-600">{{ convertMoneyTendero(funValor(producto.data_promocion.promo_valor), $tienda.idm_moneda) }}</span>
                <span v-else class="text-general f-600">{{ convertMoneyTendero(funValor(producto.precio), $tienda.idm_moneda) }}</span>
                <strike v-show="producto.promocion" class="ml-2 f-12">
                    {{ convertMoneyTendero(funValor(producto.precio), $tienda.idm_moneda) }}
                </strike>
                <div class="cr-pointer text-center ml-auto br-10 text-general-red py-1"
                     style="width:120px;"
                     @click.stop="agregar_producto(producto.id)"
                >
                    Añadir
                </div>
            </div>
            <span v-if="producto.promocion" class="bg-general br-10 text-white px-2 f-12">{{ producto.data_promocion.texto }}</span>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        producto: {
            type: Object,
            default: () => {
                return {
                    nombre: 'Malteada de vainilla Francesa con palitos de chocolate',
                    presentacion:  '600',
                    sigla: 'ml',
                    precio: 14000,
                    id_promocion: null,
                }
            }
        },
        info: {
            type: Object,
            default: () => { return { } }
        },
        tendero: {
            type: Boolean,
            default: false
        },
        agregar: {
            type: Boolean,
            default: false
        },
        ver: {
            type: Boolean,
            default: false
        },
        editar: {
            type: Boolean,
            default: false
        },
    },
    data(){
        return {
            tipo: 'libre',
            num: 1,
            colores: [ '', 'bg-gr-general', 'bg-gr-red', 'bg-gr-purple', 'bg-gr-pink' ],
            text: [ '', 'text-gr-general', 'text-gr-red', 'text-gr-purple', 'text-gr-pink' ],
            border: [ '', 'border-blue', 'border-red', 'border-purple', 'border-pink' ],
            model: null
        }
    },
    computed:{
        entero(){
            if(this.producto.cantidad_tipo === 1){
                return true
            }else{
                return false
            }
        },
        precision(){
            if(this.producto.cantidad_tipo === 1){
                return 0
            }else{
                return 2
            }
        },
        impuesto(){
            const i = this.producto.porcentaje_impuesto
            return i ? i : 0
        },
        maximaCompra(){
            if(this.producto.compra_maxima === 0){
                return 999999
            }
            if(this.producto.cantidad > this.producto.compra_maxima){
                return parseFloat(this.producto.cantidad)
            }
            return parseFloat(this.producto.compra_maxima)
        }
    },
    methods: {
        funValorEnpedido(value){
            if(this.$tienda.tipo_impuesto === 0){
                return value
            }
            if(this.$tienda.tipo_impuesto === 1){
                return value
            }
            if(this.$tienda.tipo_impuesto === 2 || this.$tienda.tipo_impuesto === 3){
                return value * (1 + this.impuesto / 100)
            }
            return 0
        },
        funValor(value){
            if(this.$tienda.tipo_impuesto === 0){
                return value
            }
            if(this.$tienda.tipo_impuesto === 1){
                return value / (1 + this.impuesto / 100)
            }
            if(this.$tienda.tipo_impuesto === 2 || this.$tienda.tipo_impuesto === 3){
                return value
            }
            return 0
        },
        cambiar_cantidad(cantidad,producto){
            if(producto.compra_maxima != 0){
                if(cantidad > producto.compra_maxima){
                    return this.notificacion('Alerta','Ha excedido la cantidad máxima de compra para este producto. Permitido '+producto.compra_maxima+' Unidades','warning');
                }
            } else {
                if(cantidad > 99999){
                    return this.notificacion('Alerta','Ha excedido la cantidad máxima de compra para este producto.','warning');
                }
            }
            const change = () => {
                // console.log(cantidad);
                this.$emit('change',{cantidad,producto})
            }
            this.delay(change)
        },
        agregar_producto(id){
            if(parseInt(this.$tienda.convenio_nomina) == 1){
                return this.notificacion('Alerta',`Lo sentimos, eres un ${this.$config.vendedor} de convenio nómina, solo los afiliados pueden hacer pedidos directamente`,'warning');
            }

            if(this.producto.adulto && this.$usuario.edad < 18){
                this.$emit('updateEdad')
            }else{
                this.$emit('agregar',id)
            }

        },
        detalle(prod){
            if(prod.desconocido) return
            this.$emit('detalle',prod)

        },
        accion_eliminar(id){
            const remover = () => {
                this.$emit('eliminar',id)
            }
            this.delay(remover)
        }
    }
}
</script>

<style lang="css" scoped>
.card-producto{
    width: 400px;
    border-radius: 16px;
}
.nombre{
    height: 38px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-line;
}
.border-blue{
    border: 1px solid #0892DD;
}
.border-red{
    border: 1px solid #FF612B;
}
.border-purple{
    border: 1px solid #9508EB;
}
.border-pink{
    border: 1px solid #D6017B;
}
</style>
